import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100dvh;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
`

const AppLayout = () => {
  return (
    <Wrapper>
      <Container>
        <Outlet />
      </Container>
    </Wrapper>
  )
}

export default AppLayout
