import { FC } from 'react';
import styled from 'styled-components';
import error from '../assets/images/error.svg';
import sbp from '../assets/images/sbp.png';
import Button from '../ui/widgets/Button/Button';

const Container = styled.div`
  height: 100vh;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  border-radius: var(--border-radius);
  padding: 16px;
`;
const HeaderBox = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.inputBorder};
  background-color: ${({theme}) => theme.inputBackground};
  padding: 25px 20px 20px 20px;
  border-radius: var(--border-radius);
`;

const ErrorText = styled.p`
  color: var(--color-red);
  font-size: 12px;
  font-weight: 400;
`;

const FieldGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const StyledField = styled.div`
  color: var(--color-text);
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  background-color: ${({theme}) => theme.inputBackground};
  border-radius: var(--border-radius);
  border: 1px solid ${({theme}) => theme.inputBorder};
  padding: 10px;
  outline: none;
  width: 100%;
`;

interface CancellationProps {
  merchantName?: string;
  merchantImage?: string;
  currency?: string;
  ticketId?: string;
  paymentMethod?: string;
  paymentMethodImage?: string;
  amount?: number;
  onConfirm: () => void;
}

const Cancellation: FC<CancellationProps> = ({
  amount,
  merchantName,
  onConfirm,
  paymentMethod,
  ticketId,
  currency = '₽',
  merchantImage,
  paymentMethodImage,
}) => {
  return (
    <Container>
      <HeaderBox>
        <img src={error} alt='error' width={46} height={46} />
        <ErrorText>Не удалось обработать ваш платеж.</ErrorText>
        <ErrorText>Пожалуйста, попробуйте еще раз</ErrorText>
      </HeaderBox>
      <FieldGroup>
        <StyledField>
          {merchantImage && (
            <img
              src={merchantImage}
              style={{ marginRight: '10px' }}
              alt='merchant'
            />
          )}
          <span>{merchantName}</span>
          {amount && (
            <span style={{ marginLeft: 'auto' }}>{currency + amount}</span>
          )}
        </StyledField>
        <StyledField >
          <img src={sbp} width={15} height={15} style={{ marginRight: '8px' }} alt='payment method' />
          <span>Пополнение через СБП</span>
        </StyledField>
      </FieldGroup>
      <FieldGroup>
        <Button
          variant='primary'
          style={{ maxWidth: '100%', fontSize: '14px', fontWeight: 600 }}
          onClick={onConfirm}
        >
          Вернуться на сайт
        </Button>
      </FieldGroup>
    </Container>
  );
};

export default Cancellation;
