import React, { useEffect } from 'react';
import styled from 'styled-components';
import success from '../assets/images/success.svg';
import Button from '../ui/widgets/Button/Button';

const Container = styled.div`
  height: 150px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin: calc(50dvh - 100px) auto;
  text-align: center;
  color: var(--color-blue);
  font-size: 18px;
  padding: 16px;

  img {
    margin-bottom: 10px;
  }
`;

const Box = styled.div`
  border: 1px solid ${({theme}) => theme.inputBorder};
  border-radius: var(--border-radius);
  background-color: ${({theme}) => theme.inputBackground};
  padding: 25px 20px 20px 20px;
`;

interface Props {
  onRedirect: () => void;
}

const Success: React.FC<Props> = ({ onRedirect }) => {
  useEffect(() => {
    const timer = setTimeout(onRedirect, 5000);
    return () => clearTimeout(timer);
  }, [onRedirect]);

  return (
    <Container>
      <Box>
        <img src={success} alt='success' width={40} height={40} />
        <p>Ваш платеж будет зачислен в&nbsp;течение нескольких минут</p>
      </Box>

      <Button style={{ width: '100%' }}>Вернуться на сайт</Button>
    </Container>
  );
};

export default Success;
