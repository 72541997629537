import styled, { css } from 'styled-components';

interface ButtonProps {
  variant?: 'primary' | 'secondary';
}

const variants = {
  primary: css`
    color: #ffffff;
    background-color: ${({ theme }) => theme.button};
    box-shadow: 0px 0px 12px 0px #00000040;
  `,
  secondary: css`
    color: var(--color-red);
    background-color: transparent;
    outline: 1px solid var(--color-red);
  `,
};

const Button = styled.button<ButtonProps>`
  font-size: 12px;
  border: none;
  border-radius: var(--border-radius);
  padding: 10px 28px;
  min-width: fit-content
  max-width: 100%;
  ${(props) => variants[props.variant!]}
`;

Button.defaultProps = {
  variant: 'primary',
};

export default Button;
