export const getCurrencySymbol = (
  value: string | undefined
): string | undefined => {
  switch (value) {
    case 'RUB':
      return ' ₽';

    case 'UAH':
      return ' ₴';

    case 'AZN':
      return ' ₼';

    case 'BTC(BTC)':
      return ' ₿';

    default:
      return value;
  }
};

export const getCardNumberMask = (cardNumber: string | undefined) => {
  const regExp = /(\d{4})(\d{4})(\d{4})(\d{4})/gi;

  if (cardNumber?.match(regExp)?.length) {
    const masked = cardNumber.replace(regExp, (_, g1, g2, g3, g4) => {
      return `${g1} ${g2} ${g3} ${g4}`;
    });
    return masked;
  }
  return cardNumber;
};
