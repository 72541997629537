import React from 'react';
import styled from 'styled-components';
import errorImage from '../assets/images/loading-error.svg';
import Button from '../ui/widgets/Button/Button';

const MessageText = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--color-red);
`;

const Container = styled.div`
  height: 90dvh;
  width: 100%;
  /* max-width: 350px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  /* margin: calc(10dvh) auto; */
  text-align: center;
  font-size: 14px;
  border-radius: var(--border-radius);
  padding: 16px;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

interface Props {
  onOk?: () => void;
  onRetry?: () => void;
}

const Error: React.FC<Props> = ({ onOk, onRetry }) => (
  <Container>
    <img src={errorImage} alt='server error' />
    {onRetry && (
      <MessageText>Транзакция недоступна, попробуйте еще раз.</MessageText>
    )}
    {onOk && <MessageText>Ошибка при загрузке страницы</MessageText>}
    <ButtonGroup>
      <Button style={{ width: '270px' }} onClick={onOk}>
        Вернуться на сайт
      </Button>
    </ButtonGroup>
  </Container>
);

export default Error;
