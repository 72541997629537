import { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import copyImage from '../../../assets/images/copy.svg';
import success from '../../../assets/images/success.svg';

const StyledField = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: ${({ theme }) => theme.inputBackground};
  border-radius: var(--border-radius);
  padding: 10px;
  outline: 1px solid ${({ theme }) => theme.inputBorder};
  border: none;
  min-width: fit-content;
  min-height: 40px;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.text};
`;

interface FieldProps {
  image?: string;
  text?: string | number;
  copy?: boolean;
  currency?: string;
  width?: string;
}

const Field: FC<FieldProps> = ({ image, text, copy, currency, width }) => {
  const [copied, setCopied] = useState(false);
  const textRef = useRef<HTMLSpanElement | null>(null);

  const handleCopy = () => {
    if (textRef.current && textRef.current.innerText) {
      // Извлекаем только числовое значение, включая десятичные дроби

      const numericValue =
        textRef.current.innerText.match(/\d+(\.\d+)?/g)?.join('') || '';

      navigator.clipboard
        .writeText(numericValue || textRef.current.innerText)
        .then(() => {
          setCopied(true);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  return (
    <StyledField
      style={{
        width: width ? width : '',
        justifyContent: copy ? 'space-between' : '',
      }}
    >
      {image && (
        <img src={image} alt={text?.toString()} height={20} width={'auto'} />
      )}
      {text !== null || text !== undefined ? (
        <StyledSpan ref={textRef}>
          {currency ? text + currency : text}
        </StyledSpan>
      ) : null}
      {copy && !copied && (
        <img
          src={copyImage}
          alt='copy'
          onClick={handleCopy}
          width={19}
          height={19}
        />
      )}
      {copied && <img src={success} alt='success' width={19} height={19} />}
    </StyledField>
  );
};

export default Field;
