import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActiveTransaction from '../components/ActiveTransaction';
import Cancellation from '../components/Cancellation';
import Error from '../components/Error';
import Spinner from '../components/Spinner';
import Success from '../components/Success';
import {
  confirmTransaction,
  declineTransaction,
  getTransaction,
  processTransaction,
} from '../services/api';
import { styled } from 'styled-components';

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ThemeButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
`;

const TransactionPage = ({
  setTheme,
}: {
  setTheme: Dispatch<SetStateAction<string>> | string;
}) => {
  const { uuid } = useParams<{ uuid: string }>();
  const [transaction, setTransaction] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const clearExistingInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    const startInterval = () => {
      clearExistingInterval();
      intervalRef.current = setInterval(async () => {
        await attemptProcessAndCheckStatus();
      }, 2000);
    };

    if (transaction?.status === 'WAITING_FOR_REQUISITE') {
      startInterval();
    } else {
      clearExistingInterval();
    }

    if (!loading && transaction?.status === 'WAITING_FOR_ACTION') {
      clearExistingInterval();
      handleRedirect(transaction.redirectUrl);
    }

    if (
      !loading &&
      transaction?.requisite?.type === 'redirect_url' && transaction?.requisite?.paymentUrl
    ) {
      clearExistingInterval();
      handleRedirect(transaction.requisite.paymentUrl);
    }

    return () => {
      clearExistingInterval();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, loading, uuid]);

  useEffect(() => {
    const collectAndFetchData = async () => {
      setLoading(true);
      try {
        const { getFingerprintData } = window.DataCollection;
        const fingerprintData = await getFingerprintData();

        if (uuid) {
          const response = await getTransaction(
            uuid,
            fingerprintData,
            transaction?.externalUserToken
          );
          if (response.data.success) {
            setTransaction(response.data.paymentRequest);
            setLoading(false);
            if (response.data.paymentRequest.status === 'CLIENT_PENDING') {
              await handleProcess();
            }
          } else {
            setError(response.data.message || 'Unknown error');
          }
        }
      } catch (error: any) {
        console.error(
          'Ошибка при сборе данных или получении транзакции:',
          error
        );
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (uuid) {
      collectAndFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (
      transaction &&
      (transaction.status === 'WAITING_FOR_SMS' ||
        transaction.status === 'PAYMENT_PENDING')
    ) {
      const transactionDate = new Date(transaction.requisite.date);
      const endTime = new Date(transactionDate.getTime() + 10 * 60000);
      const calculateTimeLeft = () => {
        const now = new Date();
        const timeLeftInSeconds = Math.max(
          0,
          Math.floor((endTime.getTime() - now.getTime()) / 1000)
        );
        return timeLeftInSeconds;
      };

      const initialTimeLeft = calculateTimeLeft();
      setTimeLeft(initialTimeLeft);

      if (initialTimeLeft <= 0) {
        // handleCancel()
      } else {
        const startTime = localStorage.getItem('startTime');
        if (!startTime) {
          const newStartTime = Math.floor(Date.now() / 1000);
          localStorage.setItem('startTime', newStartTime.toString());
        }

        const timerInterval = setInterval(() => {
          const newTimeLeft = calculateTimeLeft();
          setTimeLeft(newTimeLeft);
          if (newTimeLeft <= 0) {
            clearInterval(timerInterval);
            localStorage.removeItem('startTime');
          }
        }, 1000);

        return () => clearInterval(timerInterval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  useEffect(() => {
    if (
      transaction?.status === 'WAITING_FOR_SMS' ||
      transaction?.status === 'PAYMENT_PENDING'
    ) {
      const statusCheckInterval = setInterval(async () => {
        try {
          const { getFingerprintData } = window.DataCollection;
          const fingerprintData = await getFingerprintData();

          const response = await getTransaction(
            uuid,
            fingerprintData,
            transaction?.externalUserToken
          );
          if (response.data.success) {
            const updatedTransaction = response.data.paymentRequest;
            setTransaction(updatedTransaction);

            if (updatedTransaction.status === 'COMPLETED') {
              clearInterval(statusCheckInterval);
              return (
                <Success
                  onRedirect={() => handleRedirect(transaction.redirectUrl)}
                />
              );
            }
            if (updatedTransaction.status === 'DECLINED') {
              clearInterval(statusCheckInterval);
              return <Cancellation onConfirm={handleConfirm} />;
            }
          }
        } catch (err) {
          console.error('Error checking transaction status', err);
        }
      }, 2000);

      return () => clearInterval(statusCheckInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, uuid]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleProcess = async () => {
    try {
      setLoading(true);
      const { getFingerprintData } = window.DataCollection;
      const fingerprintData = await getFingerprintData();

      const response = await processTransaction(
        uuid,
        fingerprintData,
        transaction?.externalUserToken
      );
      if (response.status === 200) {
        setTransaction(response.data.paymentRequest);
      } else if (response.status === 503) {
        return <Error onRetry={handleRetry} />;
      }
      setLoading(false);
    } catch (err) {
      setError('Error processing transaction');
      setLoading(false);
    }
  };

  const handleRetry = () => {
    setError(null);
    handleProcess();
  };

  const handleRedirect = (url: string) => {
    window.location.replace(url);
  };

  const handleCancel = async () => {
    try {
      setLoading(true);

      const { getFingerprintData } = window.DataCollection;
      const fingerprintData = await getFingerprintData();

      const response = await declineTransaction(
        uuid,
        fingerprintData,
        transaction?.externalUserToken
      );
      if (response.status === 200) {
        setLoading(false);
        setTransaction({ ...transaction, status: 'DECLINED' });
        setTimeout(() => handleRedirect(transaction.redirectUrl), 5000);
      }
    } catch (err) {
      setError('Error cancelling transaction');
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    if (transaction.requisite.type === 'redirect_url') {
      window.location.replace(transaction.requisite.paymentUrl);
    } else {
      try {
        setLoading(true);

        const { getFingerprintData } = window.DataCollection;
        const fingerprintData = await getFingerprintData();

        const response = await confirmTransaction(
          uuid,
          fingerprintData,
          transaction?.externalUserToken
        );
        if (response.status === 200) {
          setTransaction({ ...transaction, status: 'COMPLETED' });
          setTimeout(() => handleRedirect(transaction.redirectUrl), 5000);
        } else {
          setError('Error confirming transaction');
        }
        setLoading(false);
      } catch (err) {
        setError('Error confirming transaction');
        setLoading(false);
      }
    }
  };

  const attemptProcessAndCheckStatus = async () => {
    try {
      const { getFingerprintData } = window.DataCollection;
      const fingerprintData = await getFingerprintData();

      const response = await getTransaction(
        uuid,
        fingerprintData,
        transaction?.externalUserToken
      );
      if (response.data.success) {
        setTransaction(response.data.paymentRequest);
      }

      if (response.data.paymentRequest.status !== 'WAITING_FOR_REQUISITE') {
        return;
      }
    } catch (err) {
      setError('Error processing transaction');
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error onOk={() => handleRedirect(transaction.redirectUrl)} />;
  }

  if (!transaction) {
    return <Error onOk={() => handleRedirect(transaction.redirectUrl)} />;
  }

  if (transaction.status === 'WAITING_FOR_REQUISITE') {
    return <Spinner waitingForRequisite />;
  }

  if (transaction?.status === 'DECLINED') {
    setTimeout(() => handleRedirect(transaction.redirectUrl), 5000);
    return (
      <Cancellation
        onConfirm={() => handleRedirect(transaction.redirectUrl)}
        amount={transaction.amount}
        currency={transaction.requisite.currency}
        merchantName={transaction.merchant}
        paymentMethod={transaction.paymentMethod}
        ticketId={uuid || ''}
      />
    );
  }

  if (transaction.status === 'COMPLETED') {
    return (
      <Success onRedirect={() => handleRedirect(transaction.redirectUrl)} />
    );
  }

  return (
    <WrapperDiv>
      <ThemeButton
        onClick={() =>
          typeof setTheme === 'function' &&
          setTheme((prev) => (prev === 'light' ? 'dark' : 'light'))
        }
      >
        Сменить тему
      </ThemeButton>
      {(transaction.status === 'WAITING_FOR_SMS' ||
        transaction.status === 'PAYMENT_PENDING') && (
        <ActiveTransaction
          accountNumber={
            transaction.requisite.phone ||
            transaction.requisite.wallet ||
            transaction.requisite.cardNumber ||
            (transaction.requisite.type === 'bank_account' &&
              transaction.requisite.accountNumber)
          }
          requesiteType={transaction.requisite.type}
          accountType={transaction.requisite.acquiringBank}
          amount={transaction.amount || 0}
          currency={transaction.requisite.currency}
          merchantName={transaction.merchant}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          paymentMethod={transaction.paymentMethod}
          ticketId={uuid || ''}
          timeLeft={formatTime(timeLeft || 0)}
          qrCodeData={transaction.requisite.qrCodeData}
          isRedirectUrlExist={transaction.requisite.type === 'redirect_url'}
          holderName={transaction?.requisite?.name || ''}
        />
      )}
    </WrapperDiv>
  );
};

export default TransactionPage;
